import { Fill, Style, Stroke, RegularShape } from 'ol/style';
import Text from 'ol/style/Text';

const pointStyleFunction = function (feature) {
	if (feature.get('selected') == true) {
		return [new Style({ image: bigHexagon(feature), text: createTextStyle(feature) }), new Style({ image: smallHexagon(feature) })];
	} else {
		return [new Style({ image: unselectHexagon(feature), text: createTextStyle(feature) })];
	}
};

const smallHexagon = function (feature) {
	return new RegularShape({
		fill: new Fill({ color: '#014EFA' }),
		points: 6, // Cambiar el número de puntos a 6 para representar un hexágono
		radius: 8,
		angle: 0 // Establecer el ángulo a 0 para que sea un hexágono regular
	});
};

const bigHexagon = function (feature) {
	return new RegularShape({
		fill: new Fill({ color: 'rgba(255, 255, 255, 0.4)' }),
		stroke: new Stroke({ color: '#FFFFFF', width: 1.5 }),
		points: 6, // Cambiar el número de puntos a 6 para representar un hexágono
		radius: 15,
		angle: 0 // Establecer el ángulo a 0 para que sea un hexágono regular
	});
};

const unselectHexagon = function (feature) {
	return new RegularShape({
		fill: new Fill({ color: '#FFFFFF' }),
		stroke: new Stroke({ color: '#014EFA', width: 1.5 }),
		points: 6, // Cambiar el número de puntos a 6 para representar un hexágono
		radius: 10,
		angle: 0 // Establecer el ángulo a 0 para que sea un hexágono regular
	});
};

const getFeatureText = function (feature) {
	let text = feature.get('index');
	return text;
};

const createTextStyle = function (feature) {
	return new Text({
		text: getFeatureText(feature),
		stroke: new Stroke({
			color: 'white',
			width: 5
		}),
		overflow: true,
		offsetY: feature.get('selected') == true ? -22 : -18
	});
};

export default pointStyleFunction;
