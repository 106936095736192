import { Style, Icon } from 'ol/style';

// Se utiliza como base la imagen que hay en assets\map\arrow_track.svg
const svgText =
	'<svg width="14" height="22" viewBox="0 0 14 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.62898 0.904419L13.1242 21.2332H0.133789L6.62898 0.904419Z" fill="#cccccc"/></svg>';

const cambiarColorSVG = function (svgText, colorFill) {
	// Crear un elemento div temporal para insertar el SVG y manipularlo
	const tempDiv = document.createElement('div');
	tempDiv.innerHTML = svgText;
	const svgElement = tempDiv.querySelector('svg');

	// Cambiar el color del fill
	svgElement.querySelectorAll('path').forEach((path) => {
		path.setAttribute('fill', colorFill);
	});

	// Devolver el SVG modificado como texto
	return svgElement.outerHTML;
};

const svgCache = {};

const createStyledIcon = (colorFill, rotation) => {
	const key = colorFill + '-' + rotation;
	if (!svgCache[key]) {
		const nuevoSvgText = cambiarColorSVG(svgText, colorFill);
		svgCache[key] = new Icon({
			scale: 0.5,
			src: 'data:image/svg+xml;utf8,' + encodeURIComponent(nuevoSvgText),
			rotation: rotation
		});
	}
	return svgCache[key];
};

const iconStyleFunction = function (feature) {
	const colorFill = feature.get('color');
	const rotation = feature.getProperties().rotation;

	const styledIcon = createStyledIcon(colorFill, rotation);

	return new Style({
		image: styledIcon
	});
};

export default iconStyleFunction;
