import { Polygon } from 'ol/geom';

export default {
	createBoat(buqueCoordinatesCalculated, boundsCoordinatesCalculated) {
		return {
			boat: new Polygon([buqueCoordinatesCalculated]),
			bounds: boundsCoordinatesCalculated
		};
	}
};
