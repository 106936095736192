<template>
	<div v-if="icon" class="svgicon" :class="{ halo }" :style="{ width: computedWidth + 'px', height: computedWidth + 'px' }">
		<img :src="image" />
	</div>
</template>

<script>
import Icons from '@/components/icon/icon-mappings';

export default {
	name: 'svgicon',

	props: {
		group: {
			type: String,
			required: true
		},

		icon: {
			type: String
		},

		width: {
			type: Number,
			default: 25
		},

		counterNotification: {
			type: Number
		},

		halo: {
			type: Boolean,
			default() {
				return false;
			}
		}
	},

	computed: {
		image() {
			return Icons[this.group][this.icon];
		},

		computedWidth() {
			return this.width + (this.halo ? 6 : 0);
		}
	}
};
</script>

<style lang="postcss">
@import '../../styles/app-variables.pcss';
.halo {
	border-radius: 50%;
	background-color: rgba(255, 106, 47, 0.15);
	padding: 3px;
}

.svgicon {
	position: relative;
	float: left;
	& img {
		width: 100%;
		&.colblack {
			filter: saturate(0%) grayscale(50%) brightness(69%) contrast(1000%) !important;
		}
	}
}
</style>
