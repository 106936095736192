export function getPanelOptions(identifier, feature, instantPanel = null) {
	const coordinates = generateCoordinates(feature);
	const pk = generatePk(identifier, feature, instantPanel);
	//const pk = { identifier };
	const panelId = generatePanelId(pk);
	//const panelId = identifier;

	return {
		identifier,
		panelId,
		pk,
		coordinates,
		data: feature,
		instantPanel,
		showTrackMenu: false,
		seqMovementVis: false,
		vistrack: { isClean: true, dateFrom: null, dateTo: null, visPosAis: true, visTrack: true, visWayEvents: true }
	};
}

export function generateCoordinates({ puntoAtraque, lat, lon }) {
	if (lat !== null && lon !== null) {
		return [lon, lat];
	} else {
		return null;
	}
}

export function generatePk(identifier, feature, instantPanel) {
	const { idLayer } = feature;
	if (instantPanel != null) {
		return { identifier, idLayer, instantPanel };
	} else {
		return { identifier, idLayer };
	}
}

export function generatePanelId(pk) {
	return Object.values(pk).join('::');
}
