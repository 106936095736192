export default {
	computed: {
		generalItems() {
			return {
				id: 'general',
				title: this.$t('legend.general'),
				items: [
					{
						text: this.$t('legend.ais'),
						slot: ['triangle ok']
					},
					{
						text: this.$t('legend.noais'),
						slot: ['point vessel']
					}
				]
			};
		},
		vesselTypeItems() {
			return {
				id: 'vesseltype',
				title: this.$t('legend.vesseltype'),
				items: []
			};
		},
		statusItems() {
			return {
				id: 'status',
				title: this.$t('legend.status'),
				items: []
			};
		},
		operationsItems() {
			return {
				id: 'operations',
				title: this.$t('legend.operationstype'),
				items: []
			};
		},
		legendItems() {
			return [this.generalItems, this.vesselTypeItems];
		},
		defaultItem() {
			return {
				color: this.$store.getters.defaultBackgroundColor ? this.$store.getters.defaultBackgroundColor : '#cccccc',
				coloroutline: this.$store.getters.defaultStrokeColor ? this.$store.getters.defaultStrokeColor : '#000000',
				text: this.$t('legend.default').toUpperCase(),
				shape: 'point'
			};
		},
		modifiedItem() {
			return {
				color: '#FFF554',
				coloroutline: '#CBC000',
				text: this.$t('legend.modified').toUpperCase(),
				shape: 'point'
			};
		},
		stopPositionItems() {
			return {
				id: 'aisPosition',
				title: this.$t('legend.test'),
				items: []
			};
		},
		stopPositionItem() {
			return {
				color: 'red',
				coloroutline: 'white',
				text: this.$t('legend.ais').toUpperCase(),
				shape: 'point'
			};
		},
		operationsVisItems() {
			return {
				id: 'operations',
				title: this.$t('legend.operationstype'),
				items: []
			};
		}
	}
};
