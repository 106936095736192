export default {
	methods: {
		/**
		 * Movement sequence
		 */
		drawMovementSequence(movementSequenceFeatureCreator, stop_id) {
			this.getMovementSequenceDataAndProcess(movementSequenceFeatureCreator, stop_id);
		},

		async getMovementSequenceDataAndProcess(movementSequenceFeatureCreator, stop_id) {
			const movementSequence = await this.getMovementSequence(stop_id);

			const valorMinimo = Math.min(...movementSequence.map((item) => item.nummovement));

			// Paso 1: Filtra los elementos con 'valor' igual al valor mínimo
			const movementSequenceToProcess = movementSequence.filter((item) => item.nummovement === valorMinimo);
			if (movementSequenceToProcess.length > 0) {
				// Paso 2. Procesamos
				movementSequenceFeatureCreator._processMovementSequence(movementSequenceToProcess);
			} else {
				this.$puiNotify.info(this.$puiI18n.t('operations.infomessages.noMovementSequenceShow'));
			}
		},

		async getMovementSequence(stop_id) {
			const getAisEventExtendedBody = {
				model: 'movementsequencecentroid',
				page: 1,
				rows: null,
				filter: {
					groupOp: 'or',
					groups: [],
					rules: [
						{
							field: 'stop_to',
							op: 'eq',
							data: stop_id
						},
						{
							field: 'stop_from',
							op: 'eq',
							data: stop_id
						}
					]
				}
			};

			return this.$puiRequests.postRequest(
				'/puisearch',
				getAisEventExtendedBody,
				(response) => {
					return response.data.data;
				},
				{}
			);
		}
	}
};
