<template>
	<div class="legendlistrow">
		<svg-icon v-if="group && icon" :group="group" :icon="icon" :width="20"></svg-icon>
		<div :style="styleSelected" v-else />
		<div class="legendlistelement">{{ text }}</div>
	</div>
</template>

<script>
import SvgIcon from '@/components/icon/SvgIcon';

export default {
	props: ['group', 'icon', 'text', 'shape', 'color', 'coloroutline'],

	components: {
		SvgIcon
	},

	computed: {
		styleSelected() {
			if (this.shape == 'point') {
				return this.point;
			} else if (this.shape == 'square') return this.square;
			else {
				return this.triangle;
			}
		},
		point() {
			return {
				width: '12px',
				height: '12px',
				'margin-right': '8px',
				'margin-left': '4px',
				'border-radius': '10px',
				float: 'left',
				'font-size': '10px',
				'margin-top': '5px',
				background: this.color,
				border: '2px solid ' + this.coloroutline
			};
		},
		square() {
			return {
				width: '10px',
				height: '10px',
				'margin-right': '8px',
				'margin-left': '4px',
				float: 'left',
				'font-size': '10px',
				'margin-top': '5px',
				background: this.color
			};
		},
		triangle() {
			return {
				width: '10px',
				height: '10px',
				'margin-top': '5px',
				'margin-right': '8px',
				'margin-left': '4px',
				float: 'left',
				'font-size': '10px',
				'border-left': '5px solid transparent',
				'border-right': '5px solid transparent',
				'border-bottom': '10px solid #bcf0ce',
				background: this.color
			};
		}
	}
};
</script>

<style lang="postcss">
@import '../../../../../../styles/app-variables.pcss';
.legendlistrow {
	margin-bottom: 3px;
	& .stringline {
		width: 15px;
		height: 4px;
		border-radius: 2px;
		background-color: var(--high);
		float: left;
		margin-top: 9px;
		margin-left: 2px;
		margin-right: 6px;
	}
	& .dashedline {
		width: 7px;
		height: 4px;
		border-radius: 3px;
		background-color: var(--high);
		float: left;
		margin-top: 9px;
		margin-left: 2px;
	}
	& .dashedline2 {
		width: 7px;
		height: 4px;
		border-radius: 3px;
		background-color: var(--high);
		float: left;
		margin-top: 9px;
		margin-right: 7px;
		margin-left: 1px;
	}
	& .legendlistelement {
		font-size: 12px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.33;
		letter-spacing: normal;
		padding-top: 4px;
		padding-left: 30px;
	}
}
</style>
