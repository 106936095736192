import { Style, Stroke } from 'ol/style';

const stroke = new Stroke({ color: '#f9a700', width: 1 });

const lineStyleFunction = function () {
	return new Style({
		stroke: stroke
	});
};

export default lineStyleFunction;
