import { Fill, Style, Stroke } from 'ol/style';

const fill = new Fill({ color: 'black' });
const polygonStyleFunction = function () {
	return new Style({
		stroke: new Stroke({
			color: 'black',
			width: 1.25
		}),
		fill: fill
	});
};
export default polygonStyleFunction;
