import { Fill, Style, Stroke, Circle } from 'ol/style';
import colorUtils from './colorUtils';

const pointStyleFunction = function (feature, resolution) {
	return new Style({
		image: new Circle({
			radius: 6, // Radio del círculo del punto
			fill: createFillStyle(feature),
			stroke: new Stroke({
				color: createStrokeStyle(feature),
				width: 2 // Ancho del borde del círculo
			})
		})
	});
};

const createStrokeStyle = function (feature) {
	if (!feature.get('featureVisible')) {
		return 'rgba(0, 0, 0, 0)';
	}
	if (feature.get('modified')) {
		return '#CBC000';
	}
	return colorUtils.getColorOutlineOperationsStop(feature);
};

const createFillStyle = function (feature) {
	if (!feature.get('featureVisible')) {
		return new Fill({
			color: 'rgba(0, 0, 0, 0)'
		});
	}
	if (feature.get('modified')) {
		return new Fill({
			color: '#FFF554'
		});
	}
	return new Fill({
		color: colorUtils.getColorOperationsStop(feature)
	});
};

export default pointStyleFunction;
