import mapUtils from './mapUtils';

/** Styles AIS */
import aisStyle from '@/components/operations/map/styles/aisStyle';
import aisStyleType from '@/components/operations/map/styles/aisStyleType';
import aisStyleSvg from '@/components/operations/map/styles/aisStyleSvg';
import aisStyleSvgType from '@/components/operations/map/styles/aisStyleSvgType';
import aisStyleOperations from '@/components/operations/map/styles/aisStyleOperations';
import aisStyleSvgOperations from '@/components/operations/map/styles/aisStyleSvgOperations';

/** Styles vessels bbdd */
import stopStatusStyle from '@/components/operations/map/styles/vesselStyleStatus';
import stopTypeStyle from '@/components/operations/map/styles/vesselStyleType';
import stopStatusStylePointStatus from '@/components/operations/map/styles/vesselStylePointStatus';
import stopStatusStylePointType from '@/components/operations/map/styles/vesselStylePointType';
import vesselStylePointOperations from '@/components/operations/map/styles/vesselStylePointOperations';
import vesselStyleOperations from '@/components/operations/map/styles/vesselStyleOperations';

/** Constants */
import { TYPE_VIS, STATUS_VIS } from '@/components/operations/map/sections/map/subcomponents/mapVariables';

/** 3rd-party */
import { Point } from 'ol/geom';

export default {
	/**
	 * Cambiar el estilo al elemento AIS
	 * @param {Feature} feature
	 * @param {Float} resolution
	 * @param {Geometry} geometry
	 * @param {Feature} projLon
	 * @param {Float} projLat
	 * @param {String} modeVisualization
	 */
	setStyleFeatureAIS: function (feature, resolution, geometry, projLon, projLat, modeVisualization) {
		const drawAsFarSize = mapUtils.drawAsFarSize(feature, resolution);
		if (drawAsFarSize) {
			feature.setGeometry(new Point([projLon, projLat]));
			if (modeVisualization == TYPE_VIS) {
				feature.setStyle(aisStyleSvgType);
			} else if (modeVisualization == STATUS_VIS) {
				feature.setStyle(aisStyleSvg);
			} else {
				feature.setStyle(aisStyleSvgOperations);
			}
		} else {
			feature.setGeometry(geometry);
			if (modeVisualization == TYPE_VIS) {
				feature.setStyle(aisStyleType);
			} else if (modeVisualization == STATUS_VIS) {
				feature.setStyle(aisStyle);
			} else {
				feature.setStyle(aisStyleOperations);
			}
		}
	},

	/**
	 * Cambiar el estilo al elemento vessel de bbdd
	 * @param {Feature} feature
	 * @param {Float} resolution
	 * @param {Geometry} geometry
	 * @param {Feature} projLon
	 * @param {Float} projLat
	 * @param {String} modeVisualization
	 */
	setStyleFeatureVesselBbdd: function (feature, resolution, geometry, projLon, projLat, modeVisualization) {
		const drawAsFarSize = mapUtils.drawAsFarSize(feature, resolution);
		if (drawAsFarSize) {
			feature.setGeometry(new Point([projLon, projLat]));
			if (modeVisualization == TYPE_VIS) {
				feature.setStyle(stopStatusStylePointType);
			} else if (modeVisualization == STATUS_VIS) {
				feature.setStyle(stopStatusStylePointStatus);
			} else {
				feature.setStyle(vesselStylePointOperations);
			}
		} else {
			feature.setGeometry(geometry); // Esta definido por defecto al crear el feature
			if (modeVisualization == TYPE_VIS) {
				feature.setStyle(stopTypeStyle);
			} else if (modeVisualization == STATUS_VIS) {
				feature.setStyle(stopStatusStyle);
			} else {
				feature.setStyle(vesselStyleOperations);
			}
		}
	}
};
