import { Vector as VectorSource } from 'ol/source';
import { Vector as VectorLayer } from 'ol/layer';
import WKT from 'ol/format/WKT';
import movementSequencePointStyle from '../styles/movementSequencePointStyle';

import { MOVEMENT_SEQUENCE_LAYER_ID } from '@/components/operations/map/constants/layers';

class MovementSequenceFeatureCreator {
	vectorSource = null;
	vectorLayer = new VectorLayer({
		declutter: false,
		properties: {
			id: MOVEMENT_SEQUENCE_LAYER_ID
		},
		zIndex: 300
	});

	constructor() {}

	getMovementSequenceLayer() {
		return this.vectorLayer;
	}
	/**
	 *
	 * @param {Object} movementData The movement data to display
	 */
	_processMovementSequence(movementData) {
		try {
			this.drawPointEvent(movementData);
		} catch (e) {
			console.log(e);
		}
	}

	drawPointEvent(movementData) {
		var features = [];
		const format = new WKT();

		movementData.forEach((element, index) => {
			try {
				const feature = format.readFeature(element.geometry, {
					dataProjection: 'EPSG:4326',
					featureProjection: 'EPSG:3857'
				});
				const properties = {
					geomdesc: element.geomdesc,
					numMovement: element.nummovement,
					mvnSeqId: element.mvnseqstepid,
					locationDes: element.locationdescription,
					arrivalTime: element.arrivaltime,
					actualDate: element.actualdate,
					delaySeconds: element.delayseconds,
					featureVisible: true,
					isMovementWaypoint: true
				};
				feature.setProperties(properties);
				features.push(feature);
			} catch (e) {
				console.error('Feature no añadido: ' + element.id);
			}
		});

		this.vectorSource = new VectorSource({
			features: features
		});

		this.vectorLayer.setSource(this.vectorSource);
		this.vectorLayer.setStyle(movementSequencePointStyle);
	}

	clearTrack() {
		this.vectorLayer.getSource() && this.vectorLayer.getSource().clear();
	}
}
export default MovementSequenceFeatureCreator;
