import { Vector as VectorSource } from 'ol/source';
import { Vector as VectorLayer } from 'ol/layer';
import Feature from 'ol/Feature';
import { Point } from 'ol/geom';
import trackEventPointStyle from '../styles/trackEventPointStyle';
import mapUtils from '../mapUtils';

import { AIS_TRACK_EVENT_POINT_LAYER_ID } from '@/components/operations/map/constants/layers';

class AISTrackEventPointFeatureCreator {
	vectorSource = null;
	vectorLayer = new VectorLayer({
		declutter: false,
		properties: {
			id: AIS_TRACK_EVENT_POINT_LAYER_ID
		},
		zIndex: 300
	});

	constructor() {}

	getTrackEventPointLayer() {
		return this.vectorLayer;
	}
	/**
	 *
	 * @param {Object} trackData The AIS track data to display waypoints
	 */
	_processEventPointTrack(trackData) {
		try {
			this.drawPointEvent(trackData);
		} catch (e) {
			console.log(e);
		}
	}

	drawPointEvent(trackData) {
		var features = [];

		trackData.forEach((element, index) => {
			index += 1;
			const projCoord = mapUtils.projectCoordinates(element.aislon, element.aislat);
			const projLon = projCoord[0];
			const projLat = projCoord[1];
			const point = new Point([projLon, projLat]);
			const feature = new Feature({
				geometry: point
			});
			const properties = {
				index: index.toString(),
				geomdesc: element.geomdesc,
				shipname: element.vesselname,
				speed: element.aisspeed,
				aisupdatedate: element.aisupdatedate,
				featureVisible: true,
				isEventWaypoint: true,
				eventType: element.eventtypedescription,
				colorhex: element.colorhex,
				selected: index == 1 ? true : false
			};
			feature.setProperties(properties);
			features.push(feature);
		});

		this.vectorSource = new VectorSource({
			features: features
		});

		this.vectorLayer.setSource(this.vectorSource);
		this.vectorLayer.setStyle(trackEventPointStyle);
	}

	clearTrack() {
		this.vectorLayer.getSource() && this.vectorLayer.getSource().clear();
	}
}
export default AISTrackEventPointFeatureCreator;
