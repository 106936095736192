import { Style, Icon } from 'ol/style';
import store from '@/store/store';

const svgText =
	'<svg width="28" height="52" viewBox="0 0 28 52" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14.7178 7.74837L13.75 4.02597L12.7822 7.74837L3.03218 45.2484L2.21454 48.3931L4.65887 46.2523L13.75 38.2897L22.8411 46.2523L25.2855 48.3931L24.4678 45.2484L14.7178 7.74837Z" fill="#cccccc" stroke="#000000" stroke-width="2"/></svg>';

const cambiarColorSVG = function (svgText, colorFill, colorStroke) {
	// Crear un elemento div temporal para insertar el SVG y manipularlo
	const tempDiv = document.createElement('div');
	tempDiv.innerHTML = svgText;
	const svgElement = tempDiv.querySelector('svg');

	// Cambiar el color del fill
	svgElement.querySelectorAll('path').forEach((path) => {
		path.setAttribute('fill', colorFill);
	});

	// Cambiar el color del stroke
	svgElement.querySelectorAll('path').forEach((path) => {
		path.setAttribute('stroke', colorStroke);
	});

	// Devolver el SVG modificado como texto
	return svgElement.outerHTML;
};

const createIconStyle = function (feature) {
	var backgroundColor = store.getters.defaultBackgroundColor;
	var defaultStrokeColor = store.getters.defaultStrokeColor;

	if (feature.get('statusid')) {
		const thematicColorPalete = store.getters.stopsStatusValues;
		let coloroutline = thematicColorPalete.filter((status) => status.stopstatuscode == feature.get('statusid'))[0].colorhexoutline;
		let color = thematicColorPalete.filter((status) => status.stopstatuscode == feature.get('statusid'))[0].colorhex;
		defaultStrokeColor = thematicColorPalete && feature.get('statusid') ? coloroutline : '#000000';
		backgroundColor = thematicColorPalete && feature.get('statusid') ? color : '#cccccc';
	}

	const nuevoSvgText = cambiarColorSVG(svgText, backgroundColor ? backgroundColor : '#cccccc', defaultStrokeColor ? defaultStrokeColor : '#000000');
	return new Style({
		image: new Icon({
			opacity: feature.get('featureVisible') ? 1 : 0,
			scale: 0.5,
			src: 'data:image/svg+xml;utf8,' + encodeURIComponent(nuevoSvgText),
			rotation: feature.getProperties().rotation
		})
	});
};

export default createIconStyle;
