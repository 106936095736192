<template>
	<p class="popupField">
		<strong>{{ label }}</strong> : {{ value }} {{ suffix }}
	</p>
</template>

<script>
export default {
	props: {
		label: {
			type: String,
			required: true
		},
		value: {
			type: [String, Number],
			default: ''
		},
		suffix: {
			type: String,
			required: false
		}
	}
};
</script>

<style>
.popupField {
	font-size: 12px;
}
</style>
