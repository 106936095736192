<template>
	<v-dialog v-model="computedValue" scrollable>
		<v-card class="maplegenddialog pa-0">
			<v-toolbar flat height="44">
				<v-toolbar-title class="legendtitle">{{ $t('legend.legend') }}</v-toolbar-title>
				<v-spacer></v-spacer>

				<v-btn @click="computedValue = false" small depressed min-width="36" width="36" height="36" class="mr-n3">
					<v-icon size="15">far fa-times</v-icon>
				</v-btn>
			</v-toolbar>

			<v-divider />

			<v-card-text class="pa-0 smooth-scroll">
				<div class="d-flex">
					<!-- TOC -->
					<div class="legendindex pa-4 px-5" style="width: min(300px, 30vw)">
						<div class="d-flex flex-column" style="position: absolute">
							<a
								v-for="(item, index) in toc"
								:key="index"
								@click="replaceState"
								:href="item.href"
								:class="{ 'mb-2': index < toc.length - 1 }"
								>{{ item.title }}</a
							>
						</div>
					</div>

					<!-- Legend -->
					<div ref="legendcontainer" class="d-flex flex-column flex-grow-1 legendlist pa-3" style="height: 80%">
						<div v-for="(legendItem, index) in legend" :key="index">
							<div class="legendlisttitle my-2" :id="legendItem.id">{{ legendItem.title }}</div>

							<div :class="{ grid: legendItem.grid, 'd-flex flex-column': !legendItem.grid }">
								<item v-for="(legendIcon, index) in legendItem.items" :key="index" v-bind="legendIcon">
									<div v-if="legendIcon.slot">
										<div v-for="(slot, index) in legendIcon.slot" :key="index" :class="slot"></div>
									</div>
								</item>
							</div>
						</div>
					</div>
				</div>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>
import Item from './MapLegendDialogItem';
import LegendItemsMixin from '@/mixins/LegendItemsMixin';

export default {
	name: 'maplegenddialog',

	props: ['value'],

	mixins: [LegendItemsMixin],

	components: {
		Item
	},

	computed: {
		computedValue: {
			get() {
				return this.value;
			},

			set(value) {
				this.$emit('input', value);
			}
		},

		toc() {
			return [
				{
					href: '#general',
					title: this.$t('legend.general')
				},
				{
					href: '#vesseltype',
					title: this.$t('legend.vesseltype')
				},
				{
					href: '#status',
					title: this.$t('legend.status')
				}
			];
		},

		legend() {
			var dataStatusItems = this.statusItems;
			dataStatusItems.items = this.$store.getters.stopsStatusValues;
			dataStatusItems.items['default'] = this.defaultItem;
			return this.legendItems;
		}
	},

	methods: {
		replaceState() {
			const route = `/#${this.$route.path}`;
			setTimeout(() => window.history.replaceState(null, null, route), 0);
		}
	}
};
</script>

<style lang="postcss" scoped>
@import '../../../../../../styles/app-variables.pcss';

.maplegenddialog {
	background: var(--negative);

	& .smooth-scroll {
		scroll-behavior: smooth;
	}

	& .grid {
		display: grid !important;
		grid-template-columns: 50% 50%;
		grid-template-rows: auto auto;
		grid-gap: 0px;
	}

	& .legendtitle {
		font-size: 20px;
		font-weight: bold;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.2;
		letter-spacing: normal;
		color: var(--high);
	}

	& .legendindex {
		background: var(--very-low);
		& a {
			text-decoration: none;
			font-size: 14px;
			font-weight: normal;
			font-style: normal;
			font-stretch: normal;
			line-height: 1.43;
			letter-spacing: normal;
			color: var(--high);
			&:link {
				color: var(--high);
			}
			&:visited {
				color: var(--high);
			}
			&:hover {
				color: var(--eiffel-accent);
			}
			&:active {
				color: var(--high);
			}
		}
	}
}
</style>
