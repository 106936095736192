<template>
	<v-card class="map-control map-control--top-right" :class="{ [`elevation-${expanded ? 0 : 2}`]: true }">
		<div class="d-flex align-center pa-1">
			<div class="px-1">{{ $t('legend.legend') }}</div>

			<v-btn
				height="24"
				width="24"
				depressed
				text
				icon
				:dark="expanded"
				@click="expanded = !expanded"
				class="map-legend__toggle-legend-btn ml-auto"
				:class="{ 'map-legend__toggle-legend-btn--selected': expanded }"
			>
				<v-icon size="13" :class="{ rotated: expanded }">far {{ expanded ? 'fa-minus' : 'fa-plus' }}</v-icon>
			</v-btn>
		</div>

		<div v-if="expanded">
			<v-divider />
			<slot />
		</div>
	</v-card>
</template>

<script>
export default {
	name: 'maplegendcontrol',
	data() {
		return {
			expanded: false
		};
	},
	watch: {
		expanded(expanded) {
			this.$store.commit('setMapAisLegendExpanded', expanded);
		}
	},
	destroyed() {
		this.$store.commit('setMapAisLegendExpanded', false);
	}
};
</script>

<style lang="postcss">
@import '../../../../../../styles/app-variables.pcss';
.map-control {
	width: 250px;
	position: absolute !important;
	z-index: 1;
	float: right;
	top: 1rem;
	right: 1rem;
}
.map-legend {
	&__toggle-legend-btn {
		&--selected {
			background-color: var(--primary);
			& i {
				color: var(--negative);

				&.rotated {
					transform: rotate(-180deg);
				}
			}
		}
	}

	&__row-title {
		font-size: 12px;
		font-weight: bold;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.33;
		letter-spacing: normal;
		color: var(--high);
		padding-top: 4px;
		padding-bottom: 4px;
	}

	&__row-element {
		font-size: 12px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.33;
		letter-spacing: normal;
		color: var(--medium);
	}

	& .font-weight-500 {
		font-weight: 500;
	}
}

@media screen and (max-width: 860px) {
	.map-legend {
		top: 4rem;
	}
}
</style>
