<template>
	<div ref="fullArea" class="dynamictabs d-flex flex-column flex-grow-1" style="min-height: 30% !important">
		<div v-if="shouldRenderScrollButtons" class="tabseparator"></div>

		<div v-if="!isPanelHide" v-mutate="onMutate" ref="scrollArea" :style="scrollAreaStyle">
			<tab-image v-for="(options, index) in dynamicPanels" :key="index" :options="options" :currentmap="currentmap"></tab-image>
		</div>

		<div v-if="shouldRenderScrollButtons" class="tabseparator"></div>
	</div>
</template>

<script>
import TabImage from './TabImage';

/** Constants */
import { MAP_AIS_ID } from '@/components/operations/map/sections/map/subcomponents/mapVariables';

export default {
	components: { TabImage },

	props: {
		currentmap: {
			type: String,
			required: true
		},
		isPanelHide: {
			type: Boolean,
			required: true
		}
	},

	data() {
		return {
			shouldRenderScrollButtons: false
		};
	},
	computed: {
		dynamicPanels() {
			if (this.currentmap == MAP_AIS_ID) {
				return this.$store.getters.dynamicPanelsAIS;
			}
			return this.$store.getters.dynamicPanelsPeriods;
		},
		scrollAreaStyle() {
			return {
				overflowX: 'hidden',
				overflowY: 'scroll',
				marginRight: '-18px'
			};
		}
	},
	methods: {
		onMutate() {
			if (this.dynamicPanels.length === 0) {
				return;
			}

			const actualHeight = this.dynamicPanels.length * 40;
			const availableHeight = this.$refs.fullArea.clientHeight - 28;

			this.shouldRenderScrollButtons = actualHeight > availableHeight;
		},

		async scrollDown() {
			await this.$nextTick();

			this.$refs.scrollArea.scrollTop = this.$refs.fullArea.clientHeight;
		}
	},
	watch: {
		dynamicPanels: 'scrollDown'
	}
};
</script>

<style lang="postcss">
@import '../../../../../styles/app-variables.pcss';
.dynamictabs {
	& .tabseparator {
		height: 10px;
		width: 40px;
		text-align: center;
		padding-top: 4px;
		cursor: pointer;
		font-size: 0.8rem;
		color: var(--low);
	}
}
</style>
