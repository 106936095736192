export default {
	data() {
		return {
			cartoReady: false
		};
	},
	async created() {
		this.getOperationsCarto();
	},
	methods: {
		getOperationsCarto() {
			const cartoversionVarName = 'cartoversion';
			//const userPorts = this.$store.getters.userPorts;
			let clientCartoVersion = window.localStorage.getItem(cartoversionVarName);
			if (!clientCartoVersion) {
				clientCartoVersion = {};
				this.$store.getters.getSession.properties.PORTS.forEach((port) => {
					clientCartoVersion[port.id] = port.cartoversion;
				});
				window.localStorage.setItem(cartoversionVarName, JSON.stringify(clientCartoVersion));
				const portsId = Object.keys(clientCartoVersion);
				this.requestAndSetBollardsFromServer(portsId);
				return;
			}
			clientCartoVersion = JSON.parse(clientCartoVersion);
			const dataBaseCartoVersion = {};
			this.$store.getters.getSession.properties.PORTS.forEach((port) => {
				dataBaseCartoVersion[port.id] = port.cartoversion;
			});
			const ports = Object.keys(dataBaseCartoVersion);
			const outDatedCartoPorts = [];
			ports.forEach((portId) => {
				if (dataBaseCartoVersion[portId] !== clientCartoVersion[portId]) {
					outDatedCartoPorts.push(portId);
				} else {
					const bollards = JSON.parse(window.localStorage.getItem(`cartobollards-${portId}`));
					const berths = JSON.parse(window.localStorage.getItem(`cartoberths-${portId}`));
					if (bollards && berths) {
						this.$store.commit('addBollards', bollards);
						this.$store.commit('addBerths', berths);
					} else {
						this.requestAndSetBollardsFromServer(ports);
					}
				}
			});
			if (outDatedCartoPorts.length > 0) {
				this.requestAndSetBollardsFromServer(outDatedCartoPorts);
				return;
			}
			this.cartoReady = true;
		},
		requestAndSetBollardsFromServer(portsId) {
			const requestsPromiseArray = [];
			portsId.forEach((portId) => {
				requestsPromiseArray.push(
					this.$puiRequests.getRequest('/berth/all', { portId }, (berths) => {
						if (!berths.data) {
							console.log('Error getting berths. Never should happend');
						}
						this.$store.commit('addBerths', berths.data);
						window.localStorage.setItem(`cartoberths-${portId}`, JSON.stringify(berths.data));
					})
				);
				requestsPromiseArray.push(
					this.$puiRequests.getRequest('/bollard/all', { portId }, (bollards) => {
						if (!bollards.data) {
							console.log('Error getting bollards. Never should happend');
						}
						this.$store.commit('addBollards', bollards.data);
						window.localStorage.setItem(`cartobollards-${portId}`, JSON.stringify(bollards.data));
					})
				);
			});
			Promise.all(requestsPromiseArray).then(() => {
				this.cartoReady = true;
			});
		}
	}
};
