/* GENERAL */

/* TABS */
import tabais from '@/assets/tabs/ais.svg';
import tabnaisblue from '@/assets/tabs/ais_blue.svg';

/* BASE LAYERS */
import osm from '@/assets/baselayers/OSM.png';
import satellite from '@/assets/baselayers/PNOA.png';

/* MAP ICONS */
import aisout from '@/assets/map/ais_out.svg';
import arrowtrack from '@/assets/map/arrow_track.svg';

export default {
	tabs: {
		tabais: tabais,
		tabnaisblue: tabnaisblue
	},
	baselayers: {
		osm: osm,
		satellite: satellite
	},
	map: {
		aisout: aisout,
		arrowtrack: arrowtrack
	}
};
