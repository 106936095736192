import { Fill, Style, Stroke, Circle } from 'ol/style';

const stroke = new Stroke({ color: 'rgba(255, 255, 255, 0.5)', width: 0.75 });

const pointStyleFunction = function (feature) {
	return new Style({
		image: new Circle({
			radius: 6, // Radio del círculo del punto
			fill: new Fill({ color: '#db49a0' }),
			stroke: stroke
		})
	});
};

export default pointStyleFunction;
